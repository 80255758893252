
import { login, sendCaptcha } from '@/api/index.js';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Toastrs from '@/utils/Toastrs';
@Component
export default class MoDingtalk extends Vue {
  public form: any = {
    purpose: 'login',
    mobile: '',
    passwd: ''
  };

  public async login() {
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(this.form.mobile)) {
      Toastrs.error(`请输入正确的手机号码`);
      return;
    }
    if (!this.form.passwd) {
      Toastrs.error(`请输入密码`);
      return;
    }
    const res: any = await login({
      ...this.form,
      source_appid: (this as any).appid,
      channels: 'passwd',
    });
    
    if (res.code == 200 || res.code ==0) {
      (this as any).loginSuccess(res.data.token);
    }
  }
}
